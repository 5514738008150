import React from "react";
import authContext from "../../store/auth-context";
import { useNavigate } from "react-router";
import styles from "./Auth.module.css";
import ToolTip from "../ToolTip/ToolTip";
const loadingGif = require("../../images/loading1.gif");

export default function Auth() {
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    emailTouched: false,
    pass: "",
    passTouched: false,
    user: "",
    userTouched: false,
  });
  const [confirmPass, setConfirmPass] = React.useState({
    pass: "",
    isTouched: false,
  });
  const [isLogin, setIsLogin] = React.useState(true);
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const authCtx = React.useContext(authContext);
  const navigate = useNavigate();

  function handleChange(e: any) {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
    setError("");
  }

  function confirmPassChange(e: any) {
    setConfirmPass((prev) => ({ ...prev, pass: e.target.value }));
  }

  function handleBlur(e: any) {
    const { name } = e.target;
    if (name === "confirmPass") {
      setConfirmPass((prev) => ({ ...prev, isTouched: true }));
    } else {
      setUserDetails((prev) => ({ ...prev, [name + "Touched"]: true }));
    }
  }

  const passwordInvalid =
    userDetails.pass.length < 7 || userDetails.pass.length > 25;

  const emailInvalid =
    !userDetails.email.includes("@") || userDetails.email.length === 0;

  const userInvalid =
    userDetails.user.length < 3 || userDetails.user.length > 10;

  function handleSubmit() {
    if (!isLogin) {
      if (
        passwordInvalid ||
        emailInvalid ||
        userDetails.pass !== confirmPass.pass ||
        userInvalid
      ) {
        setConfirmPass((prev) => ({ ...prev, isTouched: true }));
        setUserDetails((prev) => ({
          ...prev,
          passTouched: true,
          emailTouched: true,
          userTouched: true,
        }));
        return;
      }
    }

    let apiCall;
    if (isLogin) {
      apiCall =
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAasBNs63AV_6TlQIsEbmOQZa_ffeo9qn0";
    } else {
      apiCall =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAasBNs63AV_6TlQIsEbmOQZa_ffeo9qn0";
    }
    setIsLoading(true);

    fetch(apiCall, {
      method: "POST",
      body: JSON.stringify({
        email: userDetails.email,
        password: userDetails.pass,
        returnSecureToken: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Your request cannot be completed at this time");
        }
        return res.json();
      })
      .then((data) => {
        const expirationTime = new Date(
          new Date().getTime() + +data.expiresIn * 1000
        );
        authCtx.logIn(data.localId, data.idToken, expirationTime.toISOString());
        navigate("/");
        setIsLoading(false);
        if (!isLogin) {
          authCtx.updateUser(userDetails.user, data.localId);
        }
      })
      .catch((err) => {
        setError("We could not log you in with this information");
        setIsLoading(false);
        console.log(err);
      });
  }

  function autoLog() {
    setUserDetails({
      email: "test@hotmail.com",
      emailTouched: true,
      pass: "test1234",
      passTouched: true,
      user: "Developer",
      userTouched: true,
    });
  }

  return (
    <div className={styles.auth}>
      <h2>{isLogin ? "Welcome Back" : "Sign Up"}</h2>
      <form className={styles["auth-form"]}>
        <div className={styles["auth-form-input_container"]}>
          <label htmlFor="emailInput">Email</label>
          <input
            type="email"
            id="emailInput"
            placeholder={isLogin ? "Email" : "Enter your Email"}
            max="50"
            min="3"
            value={userDetails.email}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
          />
          {!isLogin && emailInvalid && userDetails.emailTouched && (
            <p className={styles.warning}>Please enter a valid email.</p>
          )}
        </div>
        <div className={styles["auth-form-input_container"]}>
          <label htmlFor="passwordInput">Password</label>
          <input
            type="password"
            id="passwordInput"
            max="25"
            min="7"
            placeholder={isLogin ? "Password" : "Create a password"}
            value={userDetails.pass}
            onChange={handleChange}
            onBlur={handleBlur}
            name="pass"
          />
          {!isLogin && passwordInvalid && userDetails.passTouched && (
            <p className={styles.warning}>
              {userDetails.pass.length < 7
                ? "Password must be greater than 6 characters"
                : "Password must be less than 26 characters"}
            </p>
          )}
        </div>
        {!isLogin && (
          <>
            {userDetails.pass && (
              <div className={styles["auth-form-input_container"]}>
                <label htmlFor="passwordConfirmInput">Confirm Password</label>
                <input
                  id="passwordConfirmInput"
                  name="confirmPass"
                  type="password"
                  placeholder="Confirm your password"
                  value={confirmPass.pass}
                  onChange={confirmPassChange}
                  onBlur={handleBlur}
                />
                {confirmPass.isTouched &&
                  (userDetails.pass !== confirmPass.pass ||
                    confirmPass.pass.length === 0) && (
                    <p className={styles.warning}>Passwords must match</p>
                  )}
              </div>
            )}
            <div className={styles["auth-form-input_container"]}>
              <label htmlFor="userInput">Username</label>
              <input
                id="userInput"
                name="user"
                type="text"
                value={userDetails.user}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter a profile name"
              />
              {userInvalid && userDetails.userTouched && (
                <p className={styles.warning}>Must enter a username</p>
              )}
            </div>
          </>
        )}
      </form>
      <div className={styles["button-container"]}>
        {isLoading ? (
          <img src={loadingGif} alt="loading" className={styles.loading} />
        ) : (
          <>
            <button className={styles.submit} onClick={handleSubmit}>
              {isLogin ? "Log In" : "Create Account"}
            </button>
            {isLogin && (
              <div className={styles["autofill-container"]}>
                <button onClick={autoLog}>Autofill Test Account Info</button>
                <ToolTip content="Test log in information for ease-of use testing purposes">
                  <i className="fa-solid fa-circle-question"></i>
                </ToolTip>
              </div>
            )}
          </>
        )}
      </div>
      {error && isLogin && <p className={styles.warning}>{error}</p>}
      <button
        className={styles.toggle}
        onClick={() => setIsLogin((prev) => !prev)}
      >
        {isLogin ? "Create New Account" : "Log in with existing account"}
      </button>
    </div>
  );
}
