import React, { RefObject } from "react";

const AudioElement: React.FC<{
  src: string;
  onPlaying: () => void;
  refPoint: RefObject<HTMLAudioElement>;
  isPlaying: boolean;
}> = ({ src, onPlaying, refPoint, isPlaying }) => {
  React.useEffect(() => {
    if (isPlaying) {
      refPoint.current!.play();
    } else {
      refPoint.current!.pause();
    }
  }, [refPoint, isPlaying]);

  return (
    <audio
      hidden
      data-testid="audio"
      controls
      src={src}
      autoPlay
      ref={refPoint}
      onTimeUpdate={onPlaying}
    ></audio>
  );
};

export default AudioElement;
