import React from "react";
import styles from "./Player.module.css";
import userDataContext from "../../../store/userData-context";
import AddToPlaylist from "../../Playlists/AddPlaylist/AddToPlaylist";
import Volume from "./Volume/Volume";
import ToolTip from "../../ToolTip/ToolTip";
import SliderInput from "./SliderInput/SliderInput";

interface PlayerProps {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  audioRef: React.RefObject<HTMLAudioElement>;
  songData: {
    img: string;
    name: string;
    artist: string;
    desc: string;
    id: string;
    mp3: string;
    progress?: number;
    length?: number;
  };
  changeSong: (direction: string) => void;
  setShouldRepeat: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRepeat: boolean;
  shuffle: () => void;
  isShuffling: boolean;
}

const Player: React.FC<PlayerProps> = ({
  isPlaying,
  setIsPlaying,
  audioRef,
  songData,
  changeSong,
  setShouldRepeat,
  shouldRepeat,
  shuffle,
  isShuffling,
}) => {
  const userDataCtx = React.useContext(userDataContext);
  const [isAdding, setIsAdding] = React.useState(false);
  const [volume, setVolume] = React.useState(
    JSON.parse(localStorage.getItem("volume") || "0.5")
  );

  React.useEffect(() => {
    localStorage.setItem("volume", JSON.stringify(volume));
  }, [volume]);

  const repeatStyle = {
    color: shouldRepeat ? "lightblue" : "grey",
  };
  const shuffleStyle = {
    color: isShuffling ? "lightblue" : "grey",
    cursor:
      userDataCtx.currentPlaylist.songs.length === 0
        ? "not-allowed"
        : "pointer",
  };

  function handleInput(e: any) {
    const { value } = e.target;
    const val = value / 100;
    audioRef.current!.currentTime = val * songData.length!;
  }

  function shufflePlaylist() {
    shuffle();
    setIsPlaying(true);
  }

  function convertTime(sec: number) {
    const minutes = Math.floor(sec / 60);
    const seconds = Math.floor(sec % 60);
    return (
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  }

  function toggleRepeat() {
    setShouldRepeat((prev) => !prev);
  }

  return (
    <article className={styles.player} data-testid="player">
      <div className={styles["songInfo-container"]}>
        <img
          className={styles.album}
          src={songData.img}
          alt={`${songData.name}'s song`}
        />
        <div className={styles.text}>
          <h3 className={styles.name}>{songData.name}</h3>
          <h3 className={styles.artist}>{songData.artist}</h3>
          {isAdding ? (
            <AddToPlaylist song={songData} setIsAdding={setIsAdding} />
          ) : (
            <button onClick={() => setIsAdding((prev) => !prev)}>
              Add To Playlist
            </button>
          )}
        </div>
      </div>
      <div className={styles["control-container"]}>
        <div className={styles["control-group"]}>
          <div className={styles.controls}>
            <ToolTip content="Repeat Song">
              <button
                className="button-aria"
                aria-label="Repeat current song"
                onClick={toggleRepeat}
              >
                <i
                  className="fa-solid fa-repeat"
                  style={repeatStyle}
                  data-testid="control"
                ></i>
              </button>
            </ToolTip>
            <ToolTip content="Previous Song">
              <button
                className="button-aria"
                aria-label="Go to previous song"
                onClick={() => {
                  changeSong("back");
                }}
              >
                <i className="fa-solid fa-backward" data-testid="control"></i>
              </button>
            </ToolTip>
            <ToolTip content={isPlaying ? "Pause Song" : "Play Song"}>
              <button
                className="button-aria"
                aria-label={isPlaying ? "Pause Song" : "Play song"}
                onClick={userDataCtx.togglePause}
              >
                <i
                  className={
                    isPlaying
                      ? "fa-solid fa-circle-pause"
                      : "fa-solid fa-circle-play"
                  }
                  style={{ fontSize: "4rem" }}
                  data-testid="control"
                ></i>
              </button>
            </ToolTip>
            <ToolTip content="Next Song">
              <button
                className="button-aria"
                aria-label="Go to next song"
                onClick={() => {
                  changeSong("forward");
                }}
              >
                <i className="fa-solid fa-forward" data-testid="control"></i>
              </button>
            </ToolTip>
            <ToolTip content="Shuffle Songs">
              <button
                className="button-aria"
                aria-label="Shuffle playlist"
                onClick={shufflePlaylist}
                style={shuffleStyle}
                disabled={userDataCtx.currentPlaylist.songs.length === 0}
              >
                <i
                  style={shuffleStyle}
                  className="fa-solid fa-shuffle"
                  data-testid="control"
                ></i>
              </button>
            </ToolTip>
          </div>
          <div className={styles.progress_bar} data-testid="progress-bar">
            <p className={styles["progress_bar-time"]}>
              {convertTime(audioRef.current?.currentTime || 0)}
            </p>
            <SliderInput
              value={songData.progress!}
              updateFunc={handleInput}
              maxWidth={500}
            />
            <p className={styles["progress_bar-time"]}>
              {convertTime(songData.length! || 0)}
            </p>
          </div>
        </div>
        <Volume audioRef={audioRef} volume={volume} setVolume={setVolume} />
      </div>
    </article>
  );
};
export default Player;
