import React from "react";
import authContext from "./auth-context";

interface song {
  name: string;
  id: string;
  img: string;
  desc: string;
  artist: string;
  mp3: string;
  queue?: song[];
}

const songIntitial: song = {
  name: "",
  id: "",
  img: "",
  desc: "",
  artist: "",
  mp3: "",
};
interface playlist {
  name: string;
  songs: song[];
  id: string;
  user: string;
}

const songContext = React.createContext({
  songs: [songIntitial],
  publicPlaylists: [{ name: "", songs: [songIntitial], id: "", user: "" }],
  sharedIds: [""],
  updateSharedIds: (id: string) => {},
  suggestedSongs: [songIntitial],
  filteredSongs: [songIntitial],
  searchSongs: (name: string) => {},
  removeShared: (id: string) => {},
  addShared: (playlist: playlist) => {},
});

export const SongContextProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [songs, setSongs] = React.useState<song[]>([]);
  const [publicPlaylists, setPublicPlaylists] = React.useState<playlist[]>([]);
  const [filteredSongs, setFilteredSongs] = React.useState<song[]>([]);
  const [suggestedSongs, setSuggestedSongs] = React.useState<song[]>([]);
  const [sharedIds, setSharedIds] = React.useState<string[]>([]);
  const authCtx = React.useContext(authContext);

  const searchSongs = React.useCallback(
    (name: string) => {
      const temp = songs;
      setFilteredSongs(
        temp.filter((songs) =>
          songs.name.toLowerCase().includes(name.toLowerCase())
        )
      );
    },
    [songs]
  );

  React.useEffect(() => {
    const temp = [...songs];
    for (let i = 0; i < 3; i++) {
      const num = Math.floor(Math.random() * temp.length);
      const song = temp.splice(num, 1);
      setSuggestedSongs((prev) => [...prev, ...song]);
    }
  }, [songs]);

  React.useEffect(() => {
    fetch(`https://musicapp-ae1d2-default-rtdb.firebaseio.com/Public.json`)
      .then((res) => res.json())
      .then((data) => {
        for (let playlist in data) {
          setPublicPlaylists((prev) => [
            ...prev,
            {
              ...data[playlist].playlist,
              user: data[playlist].user,
              id: playlist,
            },
          ]);
        }
      });
  }, []);

  React.useEffect(() => {
    fetch(`https://songs-34a41-default-rtdb.firebaseio.com/.json`)
      .then((res) => res.json())
      .then((data) => {
        const newArr = [];
        for (let song in data) {
          newArr.push(data[song]);
        }
        newArr.sort((a, b) => a.name.localeCompare(b.name));
        setSongs(newArr);
      });
  }, []);

  function removeShared(id: string) {
    fetch(
      `https://musicapp-ae1d2-default-rtdb.firebaseio.com/Public/${id}.json`,
      { method: "DELETE" }
    );
    setPublicPlaylists((prev) => prev.filter((playlist) => playlist.id !== id));
    setSharedIds((prev) => prev.filter((shared) => shared !== id));
  }

  function addShared(playlist: playlist) {
    updateSharedIds(playlist.id);
    setPublicPlaylists((prev) => [...prev, playlist]);
  }

  const updateSharedIds = React.useCallback((id: string) => {
    setSharedIds((prev) => [...prev, id]);
  }, []);

  React.useEffect(() => {
    if (authCtx.userId) return;
    setSharedIds([]);
  }, [authCtx.userId]);

  const contextValues = {
    songs,
    filteredSongs,
    publicPlaylists,
    suggestedSongs,
    searchSongs,
    removeShared,
    addShared,
    updateSharedIds,
    sharedIds,
  };

  return (
    <songContext.Provider value={contextValues}>
      {props.children}
    </songContext.Provider>
  );
};

export default songContext;
