import Auth from "./components/Auth/Auth";
import React from "react";
import Navbar from "./components/Navbar/Navbar";
import MainPage from "./pages/MainPage";
import authContext from "./store/auth-context";
import { Routes, Route } from "react-router-dom";
import PlaySong from "./components/PlaySong/PlaySong";
import userDataContext from "./store/userData-context";
import ShowPlaylist from "./components/Playlists/ShowPlaylist/ShowPlaylist";
import Topbar from "./components/Topbar/Topbar";
import SearchPage from "./pages/SearchPage";
import Account from "./components/Account/Account";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Transition from "./components/Transition/Transition";
import PublicPlaylists from "./pages/PublicPlaylists";

function App() {
  const authCtx = React.useContext(authContext);
  const userDataCtx = React.useContext(userDataContext);
  const { pathname } = useLocation();
  const [scroll, setScroll] = React.useState(0);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const ref = scrollRef.current!;
    ref.addEventListener("scroll", () => {
      setScroll(ref.scrollTop);
    });
    return () =>
      ref.removeEventListener("scroll", () => {
        setScroll(ref.scrollTop);
      });
  }, []);

  const style = {
    minHeight:
      userDataCtx.song.name === "" || userDataCtx.playlists.length > 0
        ? "100vh"
        : "92vh",
  };
  const contentStyle = {
    paddingBottom:
      userDataCtx.song.name !== "" || userDataCtx.playlists.length > 0
        ? "20rem"
        : "3rem",
  };

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <main style={style}>
        <Navbar />
        <div className="content" ref={scrollRef}>
          <Topbar scroll={scroll} />
          <Transition>
            <div className="content-lower" style={contentStyle}>
              <Routes>
                {userDataCtx.currentView.songs.length > 0 && (
                  <Route path="/playlist" element={<ShowPlaylist />} />
                )}
                <Route path="/" element={<MainPage />} />
                {!authCtx.isLoggedIn && (
                  <Route path="/login" element={<Auth />} />
                )}
                {authCtx.isLoggedIn && (
                  <Route path="/account" element={<Account />} />
                )}
                <Route path="/search" element={<SearchPage />} />
                <Route path="/public" element={<PublicPlaylists />} />
                <Route path="*" element={<MainPage />} />
              </Routes>
            </div>
          </Transition>
        </div>
      </main>
      {(userDataCtx.song.id ||
        userDataCtx.currentPlaylist.songs.length > 0) && <PlaySong />}
    </>
  );
}

export default App;
