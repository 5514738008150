import React from "react";
import userDataContext from "../../../store/userData-context";
import SmallCard from "../../SongCards/SmallCard/SmallCard";
import styles from "./ShowPlaylist.module.css";
import DeleteModal from "../../DeleteModal/DeleteModal";
import authContext from "../../../store/auth-context";
import songContext from "../../../store/song-context";

const ShowPlaylist = () => {
  const userDataCtx = React.useContext(userDataContext);
  const name = userDataCtx.currentView.name;
  const [askDelete, setAskDelete] = React.useState(false);
  const authCtx = React.useContext(authContext);
  const songCtx = React.useContext(songContext);
  const isOwner = songCtx.sharedIds.some(
    (id) => id === userDataCtx.currentView.id
  );

  function toggleDelete() {
    setAskDelete((prev) => !prev);
  }

  const playlistHtml = userDataCtx.currentView.songs.map((song) => (
    <div key={song.id}>
      <SmallCard
        img={song.img}
        name={song.name}
        id={song.id}
        artist={song.artist}
        mp3={song.mp3}
        desc={song.desc}
      />
      <button
        onClick={() => {
          userDataCtx.removeSong(song.id);
        }}
        className={styles.button}
      >
        Remove
      </button>
    </div>
  ));

  const isShared = songCtx.publicPlaylists.find(
    (playlist) =>
      JSON.stringify(playlist.songs) ===
      JSON.stringify(userDataCtx.currentView.songs)
  )
    ? true
    : false;

  function startPlaylist() {
    userDataCtx.setPlaylist(name, userDataCtx.currentView.songs);
  }

  function removePlaylist() {
    userDataCtx.removePlaylist(name);
    userDataCtx.setView("", []);
  }

  function uploadPlaylist() {
    if (authCtx.isDeveloper) {
      songCtx.addShared({
        name: name,
        songs: userDataCtx.currentView.songs,
        id: name,
        user: authCtx.user,
      });
      return;
    }
    fetch(`https://musicapp-ae1d2-default-rtdb.firebaseio.com/Public.json`, {
      method: "POST",
      body: JSON.stringify({
        playlist: userDataCtx.currentView,
        user: authCtx.user,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        songCtx.addShared({
          name: name,
          songs: userDataCtx.currentView.songs,
          id: data.name,
          user: authCtx.user,
        });
      });
  }

  return (
    <div>
      {askDelete && (
        <DeleteModal
          deleteFunction={removePlaylist}
          cancelFunction={toggleDelete}
        />
      )}
      <header className={styles.header}>
        <h2 className={styles["header__playlist-name"]}>
          {userDataCtx.currentView.name}
        </h2>
        <div className={styles["header__button-container"]}>
          {isOwner && (
            <button className={styles.button}>Unshare Playlist</button>
          )}
          {!userDataCtx.currentView.id && (
            <button className={styles.button} onClick={toggleDelete}>
              Delete Playlist
            </button>
          )}
          <button className={styles.button} onClick={startPlaylist}>
            Start Playlist
          </button>
          {authCtx.userId && !isShared && (
            <button className={styles.button} onClick={uploadPlaylist}>
              Share
            </button>
          )}
        </div>
      </header>
      {playlistHtml}
    </div>
  );
};

export default ShowPlaylist;
