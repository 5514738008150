import { Link } from "react-router-dom";
import authContext from "../../store/auth-context";
import styles from "./AccountNavigation.module.css";
import React from "react";

const AccountNavigation = () => {
  const authCtx = React.useContext(authContext);

  return (
    <div className={styles.modal}>
      <ul className={styles["profile-list"]}>
        <Link to="account">
          <li>Account</li>
        </Link>
        <Link to="settings">
          <li>Settings</li>
        </Link>
        <li
          onClick={() => authCtx.logOut()}
          className={styles["profile-list_logout"]}
        >
          Log Out
        </li>
      </ul>
    </div>
  );
};
export default AccountNavigation;
