import styles from "./SliderInput.module.css";

const SliderInput: React.FC<{
  value: number;
  updateFunc: (e: any) => void;
  maxWidth: number;
}> = ({ value = 0, updateFunc, maxWidth }) => {
  const progressStyle = {
    width: value ? `${value}%` : "0%",
  };

  return (
    <div
      className={styles["progress_bar-outer"]}
      id="progress"
      data-testid="progress"
      style={{ maxWidth: `${maxWidth}px` }}
    >
      <div
        className={styles["progress_bar-inner"]}
        data-testid="progress-bar-inner"
        style={progressStyle}
        id="progress1"
      ></div>
      <input
        type="range"
        value={value}
        onInput={updateFunc}
        className={styles.range}
        min={0}
        max={100}
        aria-label="Change time of song playing"
      />
    </div>
  );
};
export default SliderInput;
