import React from "react";
import songContext from "../store/song-context";
import MediumCard from "../components/SongCards/MediumCard/MediumCard";
import styles from "./Pages.module.css";

export default function PublicPlaylists() {
  const songCtx = React.useContext(songContext);
  const songs = songCtx.publicPlaylists;

  const songHtml = songs.map((playlist) => {
    const image = playlist.songs[0].img;
    return (
      <MediumCard
        key={playlist.id}
        img={image}
        id={playlist.id}
        name={playlist.name}
        desc={`Playlist shared by ${playlist.user}`}
        artist=""
        mp3=""
        queue={playlist.songs}
        isOwner={songCtx.sharedIds.includes(playlist.id)}
        deleteFunc={songCtx.removeShared}
        isPublic={true}
      />
    );
  });

  return (
    <>
      <div className={styles["search-header"]}>
        <h2>Public Playlists</h2>
      </div>
      <div
        className={styles["songs-container"]}
        style={{ justifyContent: "flex-start" }}
      >
        {songHtml}
      </div>
    </>
  );
}
