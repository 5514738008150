import React from "react";
import styles from "./MediumCard.module.css";
import userDataContext from "../../../store/userData-context";
import AddToPlaylist from "../../Playlists/AddPlaylist/AddToPlaylist";
import gsap from "gsap";
import { useNavigate } from "react-router";
const play = require("../../../images/play-circle.png");
const pauseIcon = require("../../../images/pause-icon.png");

const MediumCard: React.FC<{
  img: string;
  name: string;
  desc: string;
  id: string;
  artist: string;
  mp3: string;
  queue?: {
    img: string;
    name: string;
    desc: string;
    id: string;
    artist: string;
    mp3: string;
  }[];
  isOwner?: boolean;
  isPublic?: boolean;
  deleteFunc?: (id: string) => void;
}> = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isAdding, setIsAdding] = React.useState(false);
  const userDataCtx = React.useContext(userDataContext);
  const { name, img, desc, queue, isOwner, id, deleteFunc, isPublic } = props;
  const { pause, togglePause } = userDataCtx;
  const isPlaying = !pause && userDataCtx.song.name === name;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  function handleMouseLeave() {
    setIsHovered(false);
    setIsAdding(false);
  }
  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleClick() {
    if (pause || isPlaying) {
      togglePause();
    }
    if (queue) {
      userDataCtx.setPlaylist(name, queue);
    } else {
      userDataCtx.playSong(props);
      userDataCtx.setPlaylist("", []);
    }
  }

  function navigateToPlaylist() {
    userDataCtx.setView(name, queue!, id);
    navigate("/playlist");
  }

  const hoverStyle = {
    opacity: isHovered || isPlaying ? "1" : "0",
  };

  React.useLayoutEffect(() => {
    if (isPlaying) return;
    let ctx = gsap.context(() => {
      gsap.from(`.${styles["play-wrapper"]}`, {
        y: 50,
        duration: 0.5,
      });
      gsap.from(`.${styles["playlist-add"]}`, { y: -50, duration: 0.5 });
    }, containerRef);

    return () => ctx.revert();
  }, [isHovered, isPlaying]);

  return (
    <article
      data-testid="medium-card"
      className={styles["medium-card"]}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
      onClick={queue ? navigateToPlaylist : () => {}}
      aria-label={queue && "Show songs in playlist"}
    >
      <div className={styles.img}>
        <img className={styles.album} src={img} alt={`${name} album cover`} />
        <button
          className={"button-aria " + styles["play-wrapper"]}
          aria-label={!isPlaying ? `Play ${name}` : "Pause song playing"}
          onClick={handleClick}
          onFocus={handleMouseEnter}
          style={hoverStyle}
          data-testid="play-wrapper"
        >
          <img
            className={styles.play}
            src={isPlaying ? pauseIcon : play}
            alt="Play song"
          />
        </button>
      </div>
      <h3 className={styles.text}>{name}</h3>
      <p className={styles.desc}>{desc}</p>
      {isOwner ? (
        <button
          className={styles["playlist-add"]}
          onClick={() => deleteFunc && deleteFunc(id)}
          onFocus={handleMouseEnter}
        >
          Click to unshare
        </button>
      ) : isPublic ? (
        <button
          className={styles["playlist-add"]}
          onClick={() => userDataCtx.copyPlaylist(name, queue!)}
          onFocus={handleMouseEnter}
        >
          Copy Playlist
        </button>
      ) : (
        !isAdding && (
          <button
            className={styles["playlist-add"]}
            onClick={() => setIsAdding(true)}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            style={hoverStyle}
          >
            Add to Playlist
          </button>
        )
      )}
      {isAdding && <AddToPlaylist song={props} setIsAdding={setIsAdding} />}
    </article>
  );
};

export default MediumCard;
