import React from "react";
import ToolTip from "../../../ToolTip/ToolTip";
import SliderInput from "../SliderInput/SliderInput";
import styles from "./Volume.module.css";

const Volume: React.FC<{
  audioRef: React.RefObject<HTMLAudioElement>;
  volume: number;
  setVolume: React.Dispatch<any>;
}> = ({ setVolume, volume, audioRef }) => {
  const [isMuted, setIsMuted] = React.useState(false);
  const [prevVolume, setPrevVolume] = React.useState(volume);

  function handleMute() {
    setIsMuted((prev) => !prev);
  }
  function handleInput(e: any) {
    const { value } = e.target;
    const val = value / 100;
    audioRef.current!.volume = val;
    setVolume(audioRef.current!.volume);
  }

  React.useEffect(() => {
    setPrevVolume(volume);
  }, [volume]);

  React.useEffect(() => {
    isMuted
      ? (audioRef.current!.volume = 0)
      : (audioRef.current!.volume = prevVolume);
  }, [isMuted, audioRef, prevVolume]);

  return (
    <section className={styles["volume-changer"]}>
      <ToolTip content={isMuted ? "Unmute audio" : "Mute audio"}>
        <button
          className="button-aria"
          onClick={handleMute}
          aria-label={isMuted ? "Unmute audio" : "Mute audio"}
        >
          <i
            className={
              isMuted ? "fa-solid fa-volume-xmark" : "fa-solid fa-volume-high"
            }
          ></i>
        </button>
      </ToolTip>
      {!isMuted && (
        <SliderInput
          updateFunc={handleInput}
          value={volume * 100}
          maxWidth={150}
        />
      )}
    </section>
  );
};
export default Volume;
