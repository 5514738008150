import authContext from "./auth-context";
import React from "react";
import songContext from "./song-context";

interface song {
  name: string;
  id: string;
  img: string;
  desc: string;
  artist: string;
  mp3: string;
  queue?: song[];
}

const songIntitial: song = {
  name: "",
  id: "",
  img: "",
  desc: "",
  artist: "",
  mp3: "",
};

const userDataContext = React.createContext({
  recents: [songIntitial],
  playlists: [{ name: "", songs: [songIntitial] }],
  playSong: (obj: song) => {},
  setPlaylist: (mame: string, songs: song[]) => {},
  setView: (mame: string, songs: song[], id?: string) => {},
  currentPlaylist: { name: "", songs: [songIntitial] },
  currentView: { name: "", songs: [songIntitial], id: "" },
  song: songIntitial,
  addPlaylist: (name: string, song: song) => {},
  copyPlaylist: (name: string, songs: song[]) => {},
  removePlaylist: (name: string) => {},
  removeSong: (songId: string) => {},
  resetData: () => {},
  togglePause: () => {},
  pause: false,
});

let firstRender = true;

export const UserDataContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [recentlyPlayed, setRecentlyPlayed] = React.useState<song[]>([]);

  const [playlists, setPlaylists] = React.useState<
    {
      name: string;
      songs: song[];
    }[]
  >([]);
  const [song, setSong] = React.useState(songIntitial);
  const [currentPlaylist, setCurrentPlaylist] = React.useState<{
    name: string;
    songs: song[];
  }>({ name: "", songs: [] });
  const [pause, setPause] = React.useState(false);

  const [currentView, setCurrentView] = React.useState<{
    name: string;
    songs: song[];
    id: string;
  }>({ name: "", songs: [], id: "" });
  const authCtx = React.useContext(authContext);
  const songCtx = React.useContext(songContext);
  const { updateUser } = authCtx;
  const { updateSharedIds } = songCtx;

  function playSong(obj: song) {
    setSong(obj);
    addRecentlyPlayed(obj);
  }

  function addRecentlyPlayed(obj: song) {
    if (recentlyPlayed.some((song) => song.id === obj.id)) {
      setRecentlyPlayed((prev) => prev.filter((songs) => songs.id !== obj.id));
      setRecentlyPlayed((prev) => [obj, ...prev]);
      return;
    }
    if (recentlyPlayed.length >= 6) {
      setRecentlyPlayed((prev) => {
        const arr = prev;
        arr.pop();
        return [obj, ...arr];
      });
    } else {
      setRecentlyPlayed((prev) => [obj, ...prev]);
    }
  }

  function setPlaylist(name: string, songs: song[]) {
    setCurrentPlaylist({ name, songs });
  }

  function setView(name: string, songs: song[], id: string = "") {
    setCurrentView({ name, songs, id });
  }

  function copyPlaylist(name: string, songs: song[]) {
    setPlaylists((prev) => [...prev, { name, songs }]);
  }

  function addPlaylist(name: string, song: song) {
    if (
      playlists.some(
        (playlist) => playlist.name.toLowerCase() === name.toLowerCase()
      )
    ) {
      setPlaylists((prev) => {
        return prev.map((playlist) => {
          return playlist.name.toLowerCase() === name.toLowerCase()
            ? playlist.songs.some(
                (playlistSong) => playlistSong.name === song.name
              )
              ? playlist
              : { ...playlist, songs: [...playlist.songs, song] }
            : playlist;
        });
      });
    } else {
      setPlaylists((prev) => {
        return [...prev, { name: name, songs: [song] }];
      });
    }
  }

  function removePlaylist(name: string) {
    setPlaylists((prev) => {
      return prev.filter((playlist) => playlist.name !== name);
    });
  }

  function removeSong(songId: string) {
    setPlaylists((prev) => {
      return prev.map((playlist) => {
        return playlist.name === currentView.name
          ? {
              ...playlist,
              songs: playlist.songs.filter((songs) => songs.id !== songId),
            }
          : playlist;
      });
    });
    setPlaylists((prev) =>
      prev.filter((playlists) => playlists.songs.length !== 0)
    );

    setCurrentView((prev) => ({
      ...prev,
      songs: prev.songs.filter((song) => song.id !== songId),
    }));
  }
  function resetData() {
    setRecentlyPlayed([]);
    setPlaylists([]);
    setSong(songIntitial);
  }

  React.useEffect(() => {
    if (authCtx.userId || firstRender) {
      return;
    }

    setRecentlyPlayed([]);
    setPlaylists([]);
    firstRender = true;
  }, [authCtx.userId]);

  function togglePause() {
    setPause((prev) => !prev);
  }

  React.useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.code === "Space") {
        togglePause();
      }
    });
    return () => {
      window.removeEventListener("keydown", (event) => {
        if (event.code === "Space") {
          togglePause();
        }
      });
    };
  }, []);

  React.useEffect(() => {
    if (
      !authCtx.userId ||
      firstRender ||
      recentlyPlayed.length === 0 ||
      authCtx.isDeveloper
    ) {
      return;
    }

    fetch(
      `https://musicapp-ae1d2-default-rtdb.firebaseio.com/${authCtx.userId}.json`,
      {
        method: "PUT",
        body: JSON.stringify({
          recents: recentlyPlayed,
          playlists: playlists,
          shared: songCtx.sharedIds,
          user: authCtx.user,
        }),
      }
    );
  }, [
    authCtx.userId,
    authCtx.user,
    recentlyPlayed,
    playlists,
    authCtx.isDeveloper,
    songCtx.sharedIds,
  ]);

  React.useEffect(() => {
    if (!authCtx.userId || !firstRender) {
      return;
    }
    firstRender = false;

    fetch(
      `https://musicapp-ae1d2-default-rtdb.firebaseio.com/${authCtx.userId}.json`
    )
      .then((res) => res.json())
      .then((data) => {
        updateUser(data.user, "2");
        for (let id in data.shared) {
          updateSharedIds(data.shared[id]);
        }
        for (let song in data.recents) {
          if (
            recentlyPlayed.some((songs) => songs.id === data.recents[song].id)
          ) {
            continue;
          }
          setRecentlyPlayed((prev) => [...prev, data.recents[song]]);
        }
        for (let playlist in data.playlists) {
          if (
            playlists.some(
              (list) => list.name === data.playlists[playlist].name
            )
          ) {
            continue;
          }
          setPlaylists((prev) => [...prev, data.playlists[playlist]]);
        }
      });
  }, [authCtx.userId, recentlyPlayed, playlists, updateUser, updateSharedIds]);

  const contextValues = {
    recents: recentlyPlayed,
    setPlaylist,
    setView,
    playlists,
    addPlaylist,
    copyPlaylist,
    removePlaylist,
    removeSong,
    resetData,
    currentPlaylist,
    currentView,
    playSong,
    song,
    togglePause,
    pause,
  };

  return (
    <userDataContext.Provider value={contextValues}>
      {props.children}
    </userDataContext.Provider>
  );
};

export default userDataContext;
