import React from "react";
import styles from "./Topbar.module.css";
import authContext from "../../store/auth-context";
import SearchInput from "../Search/SearchInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccountNavigation from "../Account/AccountNavigation";

const Topbar: React.FC<{ scroll: number }> = ({ scroll }) => {
  const authCtx = React.useContext(authContext);
  const [isShowing, setIsShowing] = React.useState(false);
  const navigate = useNavigate();
  const pathName = useLocation();
  function toggleShowing() {
    setIsShowing((prev) => !prev);
  }

  const style = {
    background:
      scroll > 0
        ? scroll > 100
          ? "rgba(46, 6, 6, 1)"
          : "rgba(46, 6, 6,.5)"
        : "transparent",
  };
  const buttonStyle = {
    background: isShowing ? "rgb(36, 33, 33)" : "black",
  };

  return (
    <>
      <div className={styles.topBar} style={style} data-testid="topbar">
        {!authCtx.userId ? (
          <Link to="login">
            <button className={styles["profile-button"] + " " + styles.link}>
              Log In
            </button>
          </Link>
        ) : (
          <button
            className={styles["profile-button"]}
            style={buttonStyle}
            onClick={toggleShowing}
          >
            <div className={styles["profile-button-icon"]}>
              <i className="fa-regular fa-user"></i>
            </div>
            <p>Your Profile</p>
            <div className={styles["profile-button-carat"]}>
              <i
                className={
                  !isShowing ? "fa-solid fa-caret-down" : "fa-solid fa-caret-up"
                }
              ></i>
            </div>
          </button>
        )}
        {authCtx.isLoggedIn && isShowing && <AccountNavigation />}
        <div className={styles["button-container"]}>
          {pathName.pathname !== "/" && (
            <span
              onClick={() => navigate(-1)}
              className={styles["back-button"]}
            >
              <i className="fa-solid fa-arrow-left"></i>
              <span>Go back</span>
            </span>
          )}
          {pathName.pathname === "/search" && <SearchInput />}
        </div>
      </div>
      <div className={`${styles["button-container"]} ${styles.mobile}`}>
        {pathName.pathname !== "/" && (
          <span onClick={() => navigate(-1)} className={styles["back-button"]}>
            <i className="fa-solid fa-arrow-left"></i>
            <span>Go back</span>
          </span>
        )}
      </div>
    </>
  );
};
export default Topbar;
